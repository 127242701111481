@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("assets/fonts/Montserrat-Bold.ttf");
}

$theme-colors: (
  "warning": #f0ad4e,
  "success": #3c763d,
);

$state-success-bg: #3c763d;

@import "bootstrap/scss/bootstrap.scss";

html {
  font-size: 14px;
}
    
body {
  color: #444444; // default color
  margin: 0;
  font-family: proxima-nova, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.btn-success {
  color: #fff;
  background-color: #22B573 !important;
  border-color: #22B573 !important;
  &:hover {
    background-color: #00875A !important;
    border-color: #00875A !important;
  }
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-position: right calc(0.375em + 0.1875rem) center !important;
}